import React from 'react';
import {
  View,
  TextInput,
} from 'react-native';
import { Text, useTheme } from 'react-native-paper';

export const TicketInput = (props) => {
  const theme = useTheme();

  const [text, setText] = React.useState('');
  const item = props.items.value
  const rate = props.items.tickets
  const disabled = props.disabled
  const zone = props.gametype == 'bol' ? 'bol' : props.items.zone_id
  const limits = props.limit
  const gameid = props.gameid

  const checkLimit = (text) => {
    text.replace(/[^0-9]/g, '')
    let limit = find(limits, 'number', item);
    
    if (limit !== null) {
      if (text > 1000 - limit["amount"]) {
        props.callback(item, text, rate, zone,true)
        setText('')
      } else {
        props.callback(item, text, rate, zone,false)
      }
    } else {
      if (text > 1000) {
        props.callback(item, text, rate, zone,true)
        setText('')
      } else {
        props.callback(item, text, rate, zone,false)
      }
    }
  }
  function find(arr, key, val) {
    for (var ai, i = arr.length; i--;)
      if ((ai = arr[i]) && ai[key] == val)
        return ai;
    return null;
  }
  return (
    <View style={{ flexDirection: 'row', width: '45%', marginBottom: 10 }}>
      <View style={{ width: 35, height: 35, borderRadius: 5, borderWidth: 1, justifyContent: 'center', alignItems: 'center' }}><Text>{item}</Text></View>
      <TextInput
        style={{ borderTopWidth: 1, borderBottomWidth: 1, borderRightWidth: 1, height: 35, width: 115, paddingLeft: 10 }}
        onChangeText={text => {
          setText(text?.replace(/[^0-9]/g, ''))
          gameid == '1' ? checkLimit(text) : gameid == '2' ? checkLimit(text) : props.callback(item, text.replace(/[^0-9]/g, ''), rate, zone,false)
        }}
        disabled={disabled}
        value={text}
        placeholder="Amount"
        keyboardType="numeric"
      />
    </View>
  )
}
export const TicketInputRow = (props) => {
  const [text, setText] = React.useState('');
  const item = props.items.value
  const rate = props.items.tickets
  const zone = props.items.zone_id
  return (
    <View style={{ width: '45%', marginBottom: 10 }}>
      <View style={{ width: '100%', height: 35, borderRadius: 5, borderWidth: 1, justifyContent: 'center', alignItems: 'center' }}><Text>{item}</Text></View>
      <TextInput
        style={{ borderLeftWidth: 1, borderBottomWidth: 1, borderRightWidth: 1, height: 35, width: '100%', paddingLeft: 10 }}
        onChangeText={text => {
          setText(text)
          props.callback(item, text, rate, zone)
        }}
        value={text}
        placeholder="Amount"
        keyboardType="numeric"
      />
    </View>
  )
}