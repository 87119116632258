import React from 'react';
import {
  View,
  Linking,
  StyleSheet,
  Image,
  ScrollView,
} from 'react-native';
import { useTheme, Text, Button } from 'react-native-paper';
import { useUserStore } from '../Services/zustand'

export const Help = () => {
  const theme = useTheme();
  const { user } = useUserStore();
  return (
    <View style={[styles.container, { backgroundColor: theme.colors.background }]}>
      <ScrollView contentContainerStyle={styles.scrolls}>
        <View style={{ justifyContent: 'center', alignItems: 'center',width:'100%',flex:1 }}>
          <View style={{ borderWidth: 1, borderRadius: 5, width:'100%',padding: 10, backgroundColor: theme.colors.background, elevation: 5, marginTop: 10 }}>
          <Text style={{ fontSize: 18,marginBottom:10 }}>Our Watsapp No</Text>
          <Text style={{ fontSize: 20,marginBottom:10 }}>919233070576.</Text>
            <Text style={{ fontSize: 18 }}>Thank You</Text>
          </View>
        </View>
      </ScrollView>
    </View>
  )
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%'
  },
  scrolls: {
    width: '100%',
    flexGrow: 1,
  },
});